<template>
  <div
    class="navigation d-flex justify-center"
    :class="{ 'navigation--mobile': mobile }"
  >
    <div class="navigation-bar" :class="{ 'navigation-bar--mobile': mobile }">
      <div
        v-if="!isAllianceUser"
        class="icon-menu-tether"
      >
        <img
          contain
          class="header-menu__logo__img"
          :src="require(`@/assets/logos/${getLogo}`)"
          alt="Logo Explorador Escolar TetherEd"
        />
        <span class="header-menu__tenant">{{ getExplorerName }}</span>
      </div>
      <div
        class="buttons-menu"
        :style="{
          height: isAllianceUser ? '98vh' : null,
        }"
      >
        <div>
          <div
            class="buttons-menu__single"
            @click="goToPath('Dashboard')"
            @keydown.enter="goToPath('Dashboard')"
          >
            <img
              class="clickable"
              :src="require('@/assets/menu/' + getDashboardNav)"
              alt="Ir al explorador"
            />
          </div>

          <!-- <div class="buttons-menu__single" v-if="isGuest" @click="$emit('signUp')">
            <img
              class="clickable"
              :src="
                $i18n.locale === 'es'
                  ? require('@/assets/iconsChile/navigation/toRegister.svg')
                  : require('@/assets/iconsBase/navigation/register-en.svg')
              "
              alt="Registrar Cuenta"
            />
          </div> -->

          <div
            class="buttons-menu__single"
            @click="goToPath('Explorer')"
            @keydown.enter="goToPath('Explorer')"
          >
            <img
              class="clickable"
              :src="require('@/assets/menu/' + getExplorerNav)"
              alt="Ir al explorador"
            />
          </div>
          <div
            class="buttons-menu__single"
            @click="goToPath('Favorites')"
            @keydown.enter="goToPath('Favorites')"
          >
            <img
              class="clickable"
              :src="require('@/assets/menu/' + getListNav)"
              alt="Mi lista de establecimientos"
            />
          </div>

          <div
            v-if="false && !isGuest"
            class="buttons-menu__single"
            @click="goToPath('Application')"
            @keydown.enter="goToPath('Application')"
          >
            <img
              class="clickable"
              :src="
                isSelected('Application')
                  ? require('@/assets/menu/postular-hover.svg')
                  : require('@/assets/menu/postular.svg')
              "
              alt="Ir a postular"
            />
          </div>
          <div
            v-if="TENANT_CONFIGURATION.SETTINGS.SIMULATION.ENABLED"
            class="buttons-menu__single"
            @click="goToPath('Simulate')"
            @keydown.enter="goToPath('Simulate')"
          >
            <img
              class="clickable"
              :src="
                isSelected('Simulate')
                  ? require('@/assets/menu/admision-historica-hover.svg')
                  : require('@/assets/menu/admision-historica.svg')
              "
              alt="Ir a simulación"
            />
          </div>
          <Calendar v-if="false" v-show="currentRoute === 'Dashboard'" />
        </div>
        <div class="header__footer">
          <div class="header__qa">
            <div>
              <h5
                class="header__qa__text"
                @click="openTAC('tether_base')"
                @keydown.enter="openTAC('tether_base')"
              >
                {{ $t('header.terms') }}
              </h5>
              <h5
                class="header__qa__text"
                @click="displayMimeQa"
                @keydown.enter="displayMimeQa"
              >
                {{ $t('header.qa') }}
              </h5>
              <p
                v-t="'menu.mime-qa.contact'"
                class="header__qa__text"
                @click="showContactModal()"
                @keydown.enter="showContactModal()"
              />
            </div>
            <div v-if="false" class="d-flex mt-5">
              <div
                v-for="(locale, index) in locales"
                id="locales"
                :key="locale"
                :value="locale"
              >
                <p
                  v-t="index === 0 ? locale.toUpperCase() + ' /' : locale.toUpperCase()"
                  class="header__qa__text"
                  :class="{ 'ml-1': index === 1 }"
                  :style="$i18n.locale === locale ? 'font-weight: 500 !important;' : ''"
                  @click="setLanguage(locale)"
                  @keydown.enter="setLanguage(locale)"
                />
              </div>
            </div>
            <section class="fill-width d-flex justify-center">
              <button
                v-if="isGuest"
                class="header__footer__logout__icon"
                type="button"
                @click="signIn"
              >
                {{ $t('map.login') }}
              </button>
              <button
                v-if="!isGuest"
                v-t="'menu.close-sesion'"
                class="header__footer__logout__icon"
                type="button"
                @click="closeSession"
              />
            </section>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="showLoginDialog"
      content-class="elevation-0"
      overlay-color="transparent"
      max-width="400"
    >
      <WarningModal @close="closeWarningModal" @go-register="signUp" @go-login="signIn" />
    </v-dialog>
    <v-dialog
      content-class="explorer__legend-guest explorer__legend-guest--qa"
      :value="showMimeQa"
      width="1000"
      @click:outside="closeModal"
    >
      <FAQ
        :terms-active="0"
        :research-locale-key-root="faqTenantLocale"
        menu
        @openContactModal="showContactModal"
        @closeModal="closeMimeQa"
      />
    </v-dialog>
    <v-dialog v-model="showUserRegister" persistent max-width="360" transition="slide-x-transition">
      <user-register :step="userRegisterStep" @closeModal="closeRegisterModal" @logout="logout" />
    </v-dialog>
    <v-dialog
      v-if="showContact"
      v-model="showContact"
      max-width="660"
      transition="slide-x-transition"
      @click:outside="resetContactModal"
    >
      <Contact
        is-contact-plataform-form
        in-explorer
        :reset-modal="resetModal"
        @setResetModal="setResetModal"
      />
    </v-dialog>
    <v-dialog
      v-model="showLoginDialog"
      content-class="elevation-0"
      overlay-color="transparent"
      max-width="400"
      style="margin-left: 150px"
    >
      <WarningModal
        :path-to="pathTo"
        @close="closeWarningModal"
        @go-register="signUp"
        @go-login="signIn"
      />
    </v-dialog>
    <v-dialog
      v-model="showSystemModals"
      persistent
      :content-class="isMobile ? 'system-dialogs-mobile' : 'system-dialogs'"
      max-width="400"
      overlay-color="rgba(204, 204, 204, 0.4)"
      overlay-opacity="1"
    >
      <SystemModals
        v-if="!isGuest"
        in-nav-buttons
        :modal-index="systemModalIndex"
        @closeDialog="showSystemModals = false"
        @toApplication="toApplication()"
      />
      <SystemModals
        v-if="isGuest"
        in-nav-buttons
        :modal-index="systemModalIndex"
        :default-warning-info="warningModalInfo"
        @closeDialog="showSystemModals = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import userRegistrationService from '@/services/userRegistration.services';
import Contact from '@/components/explorer/contact/Contact.vue';
import SystemModals from '@/components/templates/SystemModals.vue';
import FAQ from '@/components/organisms/general/FAQ.vue';
import CONFIG from '@/config';
import trackMixPanel from '@/utils/mixpanel';
import { mapActions, mapGetters } from 'vuex';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import Calendar from '../dashboard/sections/Calendar.vue';
import WarningModal from './guest/WarningModal.vue';

export default {
  name: 'NavigationButtons',
  components: {
    WarningModal,
    Calendar,
    SystemModals,
    Contact,
    FAQ,
  },
  props: {
    mobile: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showLoginDialog: false,
      pathTo: null,
      systemModalIndex: 0,
      showSystemModals: false,
      showContact: false,
      showMapModal: false,
      showMimeQa: false,
      showMenu: false,
      showUserRegister: false,
      userRegisterStep: 1,
      resetModal: false,
      warningModalInfo: null,
      locales: ['en', 'es'],
      TENANT_CONFIGURATION,
    };
  },
  computed: {
    ...mapGetters({
      isGuest: 'authentication/isGuest',
      isAllianceUser: 'userRegister/isAllianceUser',
    }),
    faqTenantLocale() {
      return `faq-${TENANT_CONFIGURATION.DEFAULTS.LOCALE.split('-')[1].toLowerCase()}`;
    },
    getExplorerNav() {
      if (this.isSelected('Explorer')) {
        if (this.$i18n.locale === 'en') {
          return 'explorar-en-hover.svg';
        }
        return 'explorar-hover.svg';
      }
      if (this.$i18n.locale === 'en') {
        return 'explorar-en.svg';
      }
      return 'explorar.svg';
    },
    getListNav() {
      if (this.isSelected('Favorites')) {
        if (this.$i18n.locale === 'en') {
          return 'mi-lista-en-hover.svg';
        }
        return 'mi-lista-hover.svg';
      }
      if (this.$i18n.locale === 'en') {
        return 'mi-lista-en.svg';
      }
      return 'mi-lista.svg';
    },
    getDashboardNav() {
      if (this.isSelected('Dashboard')) {
        if (this.$i18n.locale === 'en') {
          return 'mi-panel-en-hover.svg';
        }
        return 'mi-panel-hover.svg';
      }
      if (this.$i18n.locale === 'en') {
        return 'mi-panel-en.svg';
      }
      return 'mi-panel.svg';
    },
    currentRoute() {
      return this.$route.name;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    getExplorerName() {
      let { tenant } = CONFIG;

      if (tenant === 'newhaven') {
        tenant = 'New Haven';
      } else if (tenant === 'dom') {
        tenant = 'RD';
      }

      return this.showExplorerLogo ? tenant : '';
    },
    getLogo() {
      const logoExplorer = this.$i18n.locale === 'en' ? 'explorer-main-en.svg' : 'explorer-main.svg';
      return this.showExplorerLogo ? logoExplorer : 'tether-main.svg';
    },
    showExplorerLogo() {
      const { path } = this.$route;
      const listOfPathsLogoExplorer = ['/map/', '/map', '/favorites', '/simulate', '/admissions'];

      return listOfPathsLogoExplorer.includes(path);
    },
  },
  methods: {
    ...mapActions({
      retrieveOptions: 'options/retrieveOptions',
      logoutSuccess: 'authentication/logoutSuccess',
      setLocale: 'locale/setLocale',
      setSimulationModalCompleted: 'simulation/setSimulationModalCompleted',
    }),
    openTAC(tacCode) {
      userRegistrationService.getTermsAndConditions([tacCode]).then(({ data }) => {
        const url = data[0]?.pdf_url;
        if (url) {
          window.open(url, '_blank');
        }
      });
    },
    closeModal() {
      this.selectFilter = null;
      this.showLocationModal = false;
      this.showMapModal = false;
      this.showMimeQa = false;
    },
    closeSession() {
      this.$emit('closeMenu');
      this.showMenu = false;
      this.logoutSuccess().then(() => {
        this.setSimulationModalCompleted(false);
        this.$router.push('/map');
      });
    },
    logout() {
      this.$emit('closeMenu');
      this.logoutSuccess().then(() => {
        this.setSimulationModalCompleted(false);
        this.$router.push('/map');
      });
    },
    displayMimeQa() {
      if (this.showMimeQa === false) {
        this.showMimeQa = true;
        this.showMenu = false;
      } else {
        this.showMimeQa = false;
      }
      this.customTrackMixPanel('menu_faq');
    },
    closeMimeQa() {
      this.showMimeQa = false;
    },
    closeRegisterModal() {
      this.showUserRegister = false;
      this.$emit('set-default');
    },
    showContactModal() {
      this.showMimeQa = false;
      this.showContact = true;
    },
    resetContactModal() {
      this.resetModal = true;
    },
    setResetModal() {
      this.resetModal = false;
    },
    setLanguage(language) {
      this.$i18n.locale = language;
      this.setLocale(language);
      this.retrieveOptions({ reset: true });
    },

    goToPath(name) {
      let path = '';
      switch (name) {
        case 'Explorer':
          this.pathTo = name;
          trackMixPanel('menu_explore');
          path = '/map';
          this.$emit('closeMenu');
          break;
        case 'Favorites':
          this.pathTo = name;
          this.$emit('closeMenu');
          path = '/favorites';
          trackMixPanel('menu_favorites');
          break;
        case 'Application':
          if (this.currentRoute !== 'Application') {
            this.systemModalIndex = 5;
            this.showSystemModals = true;
          }
          trackMixPanel('menu_application');
          break;
        case 'Dashboard':
          this.pathTo = name;
          if (this.isGuest) {
            this.warningModalInfo = {
              content: 'modals.warning_nav_dashboard.text',
              btn1: 'modals.warning_no_student_modal.guest-btn1',
              btn2: 'modals.warning_no_student_modal.guest-btn2',
            };
            this.systemModalIndex = 7;
            this.showSystemModals = true;
            return this.showSystemModals;
          }
          this.$emit('closeMenu');
          path = '/dashboard';
          trackMixPanel('click_dashboard_menu');
          break;
        case 'Simulate':
          this.pathTo = name;
          this.$emit('closeMenu');
          path = '/simulate';
          break;
        default:
          break;
      }
      if (this.currentRoute !== name && name !== 'Application') {
        this.$router.push(path);
      }
      return null;
    },
    isSelected(routeName) {
      const { currentRoute } = this;
      if (currentRoute === `${routeName}`) {
        return true;
      }
      return false;
    },
    signUp() {
      this.showLoginDialog = false;
      this.$emit('closeMenu');
      this.$emit('signUp');
    },
    signIn() {
      this.showLoginDialog = false;
      this.$emit('login');
    },
    closeWarningModal() {
      this.showLoginDialog = false;
    },
    toApplication() {
      trackMixPanel('modal_click_to_application');
      this.systemModalIndex = 0;
      this.showSystemModals = false;
    },
  },
};
</script>
